// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// tslint:disable:max-union-size
// tslint:disable:no-identical-functions

import * as t from "io-ts";

import * as r from "italia-ts-commons/lib/requests";

import { BPDCitizen } from "./BPDCitizen";

import { ProblemJson } from "./ProblemJson";

import { BPDTransactionList } from "./BPDTransactionList";

import { PaymentMethodDetails } from "./PaymentMethodDetails";

import { AwardsList } from "./AwardsList";

import { Wallet } from "./Wallet";

/****************************************************************
 * GetBPDCitizen
 */

// Request type definition
export type GetBPDCitizenT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly "x-citizen-id": string },
  "Authorization",
  never,
  | r.IResponseType<200, BPDCitizen, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<429, undefined, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getBPDCitizenDefaultResponses = {
  200: BPDCitizen,
  400: ProblemJson,
  401: t.undefined,
  403: ProblemJson,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetBPDCitizenResponsesT<
  A0 = BPDCitizen,
  C0 = BPDCitizen,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function GetBPDCitizenDecoder<
  A0 = BPDCitizen,
  C0 = BPDCitizen,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBPDCitizenResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
  | r.IResponseType<429, A5, never>
  | r.IResponseType<500, A6, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBPDCitizenDefaultResponses as unknown) as GetBPDCitizenResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, never>(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        never
      >(429, type[429])) as r.ResponseDecoder<r.IResponseType<429, A5, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A6, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const GetBPDCitizenDefaultDecoder = () => GetBPDCitizenDecoder();

/****************************************************************
 * GetBPDTransactions
 */

// Request type definition
export type GetBPDTransactionsT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly "x-citizen-id": string },
  "Authorization",
  never,
  | r.IResponseType<200, BPDTransactionList, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, ProblemJson, never>
  | r.IResponseType<429, undefined, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getBPDTransactionsDefaultResponses = {
  200: BPDTransactionList,
  400: ProblemJson,
  401: t.undefined,
  403: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetBPDTransactionsResponsesT<
  A0 = BPDTransactionList,
  C0 = BPDTransactionList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function GetBPDTransactionsDecoder<
  A0 = BPDTransactionList,
  C0 = BPDTransactionList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBPDTransactionsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<429, A4, never>
  | r.IResponseType<500, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBPDTransactionsDefaultResponses as unknown) as GetBPDTransactionsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, never>(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        never
      >(429, type[429])) as r.ResponseDecoder<r.IResponseType<429, A4, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const GetBPDTransactionsDefaultDecoder = () =>
  GetBPDTransactionsDecoder();

/****************************************************************
 * GetBPDPaymentInstrument
 */

// Request type definition
export type GetBPDPaymentInstrumentT = r.IGetApiRequestType<
  {
    readonly Bearer: string;
    readonly "x-citizen-id": string;
    readonly hpan: string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, PaymentMethodDetails, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getBPDPaymentInstrumentDefaultResponses = {
  200: PaymentMethodDetails,
  400: ProblemJson,
  401: t.undefined,
  403: ProblemJson,
  404: ProblemJson,
  500: ProblemJson
};

export type GetBPDPaymentInstrumentResponsesT<
  A0 = PaymentMethodDetails,
  C0 = PaymentMethodDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function GetBPDPaymentInstrumentDecoder<
  A0 = PaymentMethodDetails,
  C0 = PaymentMethodDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBPDPaymentInstrumentResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
  | r.IResponseType<500, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBPDPaymentInstrumentDefaultResponses as unknown) as GetBPDPaymentInstrumentResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d404
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const GetBPDPaymentInstrumentDefaultDecoder = () =>
  GetBPDPaymentInstrumentDecoder();

/****************************************************************
 * GetBPDAwards
 */

// Request type definition
export type GetBPDAwardsT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly "x-citizen-id": string },
  "Authorization",
  never,
  | r.IResponseType<200, AwardsList, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<429, undefined, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getBPDAwardsDefaultResponses = {
  200: AwardsList,
  400: ProblemJson,
  401: t.undefined,
  403: ProblemJson,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetBPDAwardsResponsesT<
  A0 = AwardsList,
  C0 = AwardsList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function GetBPDAwardsDecoder<
  A0 = AwardsList,
  C0 = AwardsList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBPDAwardsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
  | r.IResponseType<429, A5, never>
  | r.IResponseType<500, A6, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBPDAwardsDefaultResponses as unknown) as GetBPDAwardsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, never>(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        never
      >(429, type[429])) as r.ResponseDecoder<r.IResponseType<429, A5, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A6, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const GetBPDAwardsDefaultDecoder = () => GetBPDAwardsDecoder();

/****************************************************************
 * GetPMWalletV2
 */

// Request type definition
export type GetPMWalletV2T = r.IGetApiRequestType<
  { readonly Bearer: string; readonly "x-citizen-id": string },
  "Authorization",
  never,
  | r.IResponseType<200, Wallet, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<429, undefined, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getPMWalletV2DefaultResponses = {
  200: Wallet,
  400: ProblemJson,
  401: t.undefined,
  403: ProblemJson,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetPMWalletV2ResponsesT<
  A0 = Wallet,
  C0 = Wallet,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function GetPMWalletV2Decoder<
  A0 = Wallet,
  C0 = Wallet,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPMWalletV2ResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
  | r.IResponseType<429, A5, never>
  | r.IResponseType<500, A6, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPMWalletV2DefaultResponses as unknown) as GetPMWalletV2ResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, never>(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        never
      >(429, type[429])) as r.ResponseDecoder<r.IResponseType<429, A5, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A6, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const GetPMWalletV2DefaultDecoder = () => GetPMWalletV2Decoder();
